<script setup lang="ts">
const { toast } = defineProps<{
    toast: Toast;
}>();

const countdown = ref('');
const diff = ref(0);
let interval: NodeJS.Timeout;
const localeRoute = useLocaleRoute();

function updateCountdown() {
    const confirmationTimeLimit = toast.data as Date;
    if (!confirmationTimeLimit) {
        return;
    }
    diff.value = confirmationTimeLimit.getTime() - zurichDate().getTime();
    const minutes = Math.floor(diff.value / 1000 / 60);
    const seconds = Math.floor(diff.value / 1000) % 60;
    countdown.value = `${minutes}:${seconds.toString().padStart(2, '0')}`;

    if (diff.value <= 0) {
        const cartStore = useCartStore();
        clearInterval(interval);

        if (Object.values(cartStore.offers).length > 0) {
            navigateTo(localeRoute({ name: 'checkout-step-1-cart' })?.href, { external: true });
        }
        else {
            navigateTo('/', { external: true });
        }
    }
}

onMounted(() => {
    interval = setInterval(updateCountdown, 1000);
    updateCountdown();
});

onUnmounted(() => {
    clearInterval(interval);
});
</script>

<template>
    <Toast :toast="toast" class="bg-accent-1 text-white">
        <template #icon>
            <Icon name="tot:clock" class="size-4" />
        </template>
        <div>
            {{ $te(toast.message) ? $t(toast.message, { time: countdown }) : toast.message }}
        </div>
    </Toast>
</template>
